<template>
  <div class="Volunteers">
    <div class="Volunteers-title">
      <div class="Volunteers-title-top" style="color: #333333">
        志愿者在行动 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ title==undefined?'志愿服务活动执行模版（校园版）': title}}
      </div>
      <div class="Volunteers-data">
        <pre v-if="title=='志愿服务活动执行模版（校园版）' ||  title==undefined" style="line-height: 28px;">
          <div style="
          margin-left:251px;
font-size: 32px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #333333;">志愿服务活动执行模版（校园版）</div>
<div style="width: 1033px;
height: 1px;
background: #F4F5F7;"></div>
            目的：为了加强天地博爱品牌推广与项目拓展，将“储蓄保”走进校园，普及消费养老政策，帮助大学生群体提
        高养老国情认知观念，发展成“消费养老，储蓄未来”的志愿者，同时能够转化为种子用户，为社会公益贡献一份力
        量的同时，也能够帮助大学生自主创业！本着“成本把控，效率至上”的态度达到预定效果，特制定志愿服务活动方案。

        前期筹备： 

            一、主体联络：联络院校方说明情况，请求配合，联合活动；
            二、活动主题：节日类、关爱类、科普类、品销类等；
            三、活动场景：有限空间（电教室、展厅、体育场馆等）；开放空间（广场、路演等）
            四、参与人数：有限空间30-50人；开放空间100-500人；

        活动准备:
            工作人员：有限空间4人（现场调度1人、场控1人、后勤1人、联络1人）
                      开放空间6人（现场调度1人、场控2人、后勤2人、联络1人） 
                      
        物料准备：
          1、品牌宣传：易拉宝（3-5）、主题横幅（1条）、三折页（100张）、企宣册（100册）、logo手提袋（100
        个） 
          2、随手礼：logo挂件（100个）、学习礼盒（笔记本、笔）100份等；
          3、后勤：工作识别牌（按需工作配备）瓶装水10*12件¥10.9/提、碳素笔100支、急救药箱（额温枪1支、速效
        救心丸1瓶、碘伏棉签1盒—24支/盒、医用棉球2包—5枚/包、碘伏消毒液1瓶60ml/瓶、医用纱布块（灭菌型）—
        100片/盒、医用外科口罩（单片装）2盒—50张/盒、75%酒精消毒液1瓶100ml/
        瓶、免洗手消毒凝胶1瓶500ml/瓶、医疗垃圾袋1包—50个/包、云南白药创可贴1盒

            活动频次：每周一场
        </pre>
        <pre v-else  style="line-height: 28px;">
          <div style="
          margin-left:251px;
font-size: 32px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #333333;">志愿服务活动执行模版（社区版）</div>
<div style="width: 1033px;
height: 1px;
background: #F4F5F7;"></div>
            目的：为了加强天地博爱品牌推广与项目拓展，将“储蓄保”走进社区，零距离接触老百姓，用实际行动以公益
        形式将“个人养老，储蓄未来”的理念根植于老百姓心中，更能够持续培植种子用户，并为社会公益贡献一份力量，
        本着“成本把控，效率至上”的态度达到预定效果，特制定志愿服务方案。

        前期筹备： 

            一、上级报备：联络活动区域的上级单位（街道、居委会），说明情况，请求配合，联合活动；
            二、活动主题：节日类、关爱类、科普类、品销类等；
            三、活动场景：有限空间（电教室、展厅、体育场馆等）；开放空间（广场、路演等）
            四、参与人数：有限空间30-50人；开放空间100-500人；

        活动准备:
            工作人员：有限空间4人（现场调度1人、场控1人、后勤1人、联络1人）
                      开放空间6人（现场调度1人、场控2人、后勤2人、联络1人） 
                      
        物料准备：
          1、品牌宣传：易拉宝（3-5）、主题横幅（1条）、三折页（100张）、企宣册（100册）、logo手提袋（100
        个） 
          2、随手礼：logo挂件（100个）、生活礼包（米/面/油/蛋/奶）100份等；
          3、后勤：工作识别牌（按需工作配备）瓶装水10*12件¥10.9/提、碳素笔100支、急救药箱（额温枪1支、速效
        救心丸1瓶、碘伏棉签1盒—24支/盒、医用棉球2包—5枚/包、碘伏消毒液1瓶60ml/瓶、医用纱布块（灭菌型）—
        100片/盒、医用外科口罩（单片装）2盒—50张/盒、75%酒精消毒液1瓶100ml/瓶、免洗手消毒凝胶1瓶500ml/
        瓶、医疗垃圾袋1包—50个/包、创可贴）

            活动频次：每周一场
        </pre>
      </div>
    </div>
    <bottoms></bottoms>
  </div>
</template>

<script>
import bottoms from "./Bottom.vue";
export default {
  components: {
    bottoms,
  },
  data() {
    return {
      title: sessionStorage.getItem("title"),
    };
  },
  watch: {
    $route(to) {
      this.title = to.query.name;
    },
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f4f5f7");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.Volunteers {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .Volunteers-title {
    margin-top: 84px;
    // width: 890px;
    width: 1170px;
    display: flex;
    flex-direction: column;
    .Volunteers-title-top {
      padding: 15px 0 27px 0;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .Volunteers-data {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    // padding: 49px 70px 52px 199px;
    font-size: 16px;
    font-weight: 400;
    color: #333333;

  }
}
</style>