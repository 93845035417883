<template>
  <div class="chang">
    <div class="neirong">
      <img
        src="https://cdn.bjtdba.com/vod/image/2022-09-21/20220921172247546.png"
        alt=""
        mode="widthFix"
      />
    </div>
    <div class="classification">
        <div class="classification-tab">
            <div class="classification-item"
                :class="{ active: itemIndex == index }"
                v-for="(item, index) in classificationList"
                :key="index"
                @click="btnClick(index)"
            >
                {{ item.name }}
            </div>
            <div class="classification-item" @click="customerservice">
                在线客服
            </div>
        </div>
        <div class="classification-tabnav" v-for="(item, index) in classificationList" :key="index" v-show="itemIndex == index">
            <div class="tabnav-list" v-for="(item, index) in item.data" :key="index">
                <img class="tabnav-list-img" :src="item.img" alt="">
                <div  class="tabnav-list-title">{{item.title}}</div>
            </div>
        </div>
    </div>
    <bottoms></bottoms>
  </div>
</template>
<script>
import bottoms from "./Bottom.vue";
export default {
  components: {
    bottoms,
  },
  data() {
    return {
        itemIndex: 0,
        classificationList: [
            {
                name: "婴幼儿专区",
                data: [
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-21/20220921171850100.png",
                        title:"婴幼儿才艺专区"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-21/20220921195340777.png",
                        title:"婴幼儿益智专区"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-21/20220921195537289.png",
                        title:"婴幼儿特食专区"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-21/20220921195639924.png",
                        title:"婴幼儿特需专区"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-21/20220921195706303.png",
                        title:"会员专区"
                    },
                ]
            },
            {
                name: "学生专区",
                data: [
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092226790.png",
                        title:"学生助学基金"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092248784.png",
                        title:"学生校外实践积分查询"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-21/20220921171850100.png",
                        title:"学生志愿者报名须知"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092423349.png",
                        title:"应届实习"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092553614.png",
                        title:"应届供需见面会（企业专场）"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092616328.png",
                        title:"应届就业补贴"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092641477.png",
                        title:"在校项目路演（资本专场）"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092707371.png",
                        title:"应届在校项目路演（资本专场）"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092748285.png",
                        title:"进修深造委培"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092814119.png",
                        title:"会员专区"
                    },
                ]
            },
            {
                name: "职场专区",
                data: [
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093512223.png",
                        title:"供应链"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093546210.png",
                        title:"产业链"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093802937.png",
                        title:"价值链"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093611780.png",
                        title:"数据链"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093632229.png",
                        title:"生态链"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093656434.png",
                        title:"民生链"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093719200.png",
                        title:"劳保专区"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922094508250.png",
                        title:"会员专区"
                    },
                ]
            },
            {
                name: "优抚专区",
                data: [
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092926966.png",
                        title:"军属专区"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922092953952.png",
                        title:"医护专区"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093018664.png",
                        title:"司法专区"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093040164.png",
                        title:"公安武警专区"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922094508250.png",
                        title:"会员专区"
                    },
                ]
            },
            {
                name: "老年专区",
                data: [
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093142837.png",
                        title:"老年大学"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093204174.png",
                        title:"老年健康"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093225681.png",
                        title:"老年事业"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093247436.png",
                        title:"居家养老"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093310337.png",
                        title:"临终关怀"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093336786.png",
                        title:"自残专区"
                    },
                ]
            },
            {
                name: "会员专区",
                data: [
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922094544136.png",
                        title:"会员权益"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093933715.png",
                        title:"我要入会"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922093954174.png",
                        title:"本季特惠"
                    },
                ]
            },
            {
                name: "志愿者专区",
                data: [
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922094014405.png",
                        title:"志愿者商品区"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-22/20220922094033901.png",
                        title:"自愿者权益"
                    },
                ]
            },
            {
                name: "大仓",
                data: [
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-21/20220921200339490.png",
                        title:"大仓商户优品推荐"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-21/20220921200411946.png",
                        title:"主题日仓主推荐"
                    },
                    {
                        img:"https://cdn.bjtdba.com/vod/image/2022-09-21/20220921200437145.png",
                        title:"天天上新"
                    },
                ]
            },
        ],
    };
  },
  created() {},
  methods: {
    //   
    btnClick(index) {
      this.itemIndex = index;
    },
    customerservice(){
        window.open("https://chatbot.aliyuncs.com/intl/index.htm?from=L5oHJQUTmk");
    }
  },
};
</script>
<style lang="scss" scoped>
.chang {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F4F5F7;
  .neirong {
    margin-top: 84px;
    width: 100%;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .classification{
    // width: 61%;
    width: 1166px;
    font-size: 16px;

    .classification-tab{
        display: flex;
        justify-content: space-between;
        margin: 88px 0 50px 0;

        .classification-item{
            width: 110px;
            height: 42px;
            border: 1px solid #333;
            border-radius: 21px;
            text-align: center;
            line-height: 42px;
            cursor: pointer;
        }
        .active{
            border-color: #C6152F;
            background: #C6152F;
            color: #FFFFFF;
        }
    }
    .classification-tabnav{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 19px;

        .tabnav-list{
            width: 376px;
            // height: 260px;
            background: #FFFFFF;
            margin: 0 0 60px 0;
            

            .tabnav-list-img{
                width: 100%;
            }
            .tabnav-list-title{
                padding: 15px 0 15px 16px;
            }
        }
        .tabnav-list:hover{
            box-shadow: 0px 8px 65px 0px rgba(198, 21, 47, 0.3);
            -webkit-box-shadow: 0px 8px 65px 0px rgba(198, 21, 47, 0.3);
            -moz-box-shadow: 0px 8px 65px 0px rgba(198, 21, 47, 0.3);
        }
        .tabnav-list:last-child{
            flex-grow: 1;
        }
    }
  }
}
</style>