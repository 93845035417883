<template>
  <div class="chang">
    <div class="neirong">
      <img
        src="https://cdn.bjtdba.com/vod/image/2022-09-21/20220921153117117.png"
        alt=""
        mode="widetFix"
      />
    </div>
    <div class="liudaotu">
      <img src="https://cdn.bjtdba.com/vod/image/2023-02-17/20230217112254183.png" alt="" mode="widetFix">
    </div>
    <bottoms></bottoms>
  </div>
</template>
<script>
import bottoms from "./Bottom.vue";
export default {
  components: {
    bottoms,
  },
  data() {
    return {
     
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.chang {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .neirong {
    margin-top: 84px;
    width: 100%;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
  .liudaotu{
    width: 1159px;
    height: 1594px;
    margin-top: 140px;
    img{
      width: 100%;
      display: block;
    }
  }
}
</style>