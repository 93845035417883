<template>
<div>

      <div class="bottom">
    <img
      src="https://cdn.bjtdba.com/vod/image/2022-09-23/20220923095051116.png"
      alt=""
      mode="widthFix"
    />
    <div class="dibuwang">
      <div class="shyjusd">
        <div>
          <span style="color: #ffffff; font-size: 18px; font-weight: 400"
            >全国统一服务热线</span
          >
          <span
            style="
              font-weight: 400;
              font-size: 24px;
              color: #ffffff;
              margin-left: 15px;
            "
            >400-0013-088</span
          >
        </div>
        <div
          style="
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          "
        >
          有尊严而优雅的老去，全民养老解决方案提供商
        </div>
      </div>
    </div>
    
  </div>


  <div class="haoma">
      COPYRIGHT（c）2010-2022 北京天地博爱网络科技有限公司 ALL Rights Reserved
      <span @click="sdf()" style="cursor: pointer">
        <span style="margin: 0 5px 0 10px">|</span>京ICP备2020038339号</span>
      <span @click="gongan()" style="cursor: pointer"><span style="margin: 0 5px 0 10px">|</span>京公网安备11010502042909</span>
      <span style="margin: 0 5px 0 10px">|</span>
      <span>增值电信许可证：京B2-20203125</span>
    </div>
</div>

  
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    sdf() {
      console.log(111)
      window.location.href = "https://beian.miit.gov.cn/#/Integrated/index";
    },
    gongan() {
      window.location.href ="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502042909";
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin-top: 100px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    display: block;
  }
  .dibuwang {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1111;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .shyjusd {
      width: 35%;
      height: 35%;
      margin-top: 3.3%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
 
}
 .haoma {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1a1d25;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
  }
</style>