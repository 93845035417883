<template>
  <div class="chang">
    <div class="neirong">
      <div class="chang-title">
        <span style="font-size: 14px; font-weight: 400">企业动态</span>
        <i class="el-icon-arrow-left"></i>
        <span style="font-size: 16px; font-weight: 400">{{ title }}</span>
      </div>
      <div class="textht">
        <div v-html="content"></div>
      </div>
    </div>
    <bottoms></bottoms>
  </div>
</template>
<script>
import { detail } from "../request/api.js";
import bottoms from "./Bottom.vue";
export default {
  components: {
    bottoms,
  },
  data() {
    return {
      article_id: "",
      // data: "",1
      content: '',
      title: ''

    };
  },
  created() {
    this.article_id = this.$route.query.article_id;
    this.Adetail();
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f4f5f7");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  methods: {
    Adetail() {
      detail({
        article_id: this.article_id,
      }).then(
        (res) => {
          if (res.status == 200) {
            this.content = res.data.content.content;
            this.title = res.data.title
          }
        },
        (fail) => {
          console.log(fail);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ i {
  font-size: 20px;
}
/deep/ img {
        max-width: 100% !important;
      }
.chang {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .neirong {
    margin-top: 84px;
    width: 1170px;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    .chang-title {
      color: #333333;
      margin: 24px 0 29px 0;
      display: flex;
      align-items: center;
    }

    .textht {
      background-color: 100%;
      background-color: #FFFFFF;
      padding: 49px 150px;
    }
  }

}</style>