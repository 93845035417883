<template>
  <div class="chang">
    <div class="neirong">
      <img
        src="https://cdn.bjtdba.com/vod/image/2022-09-21/20220921135206350.png"
        alt=""
        mode="widetFix"
      />
    </div>
    <div class="dataList">
      <div class="dataList-yi" v-for="(item, index) in list" :key="index" @click="xiangqing(item)">
        <div class="dataList-yi-nei">
          <div class="dataList-yi-nei-left">
            <img :src="item.image_input" alt="" mode="widthFix" />
          </div>
          <div class="dataList-yi-nei-right">
            <div style="font-weight: bolder; font-size: 16px">
              {{ item.title}}
            </div>
            <div style="margin-left: 26px; line-height: 33px; font-size: 14px">
              {{ item.synopsis.length>=85?item.synopsis.slice(0,85)+'...':item.synopsis}}
            </div>
          </div>
          <div class="dataList-yi-pros">
            <div class="dataList-yi-pros-left">
              {{ item.author }}
            </div>
            <div class="dataList-yi-pros-right">
              {{ item.create_time }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fenye">
      <el-pagination background layout="prev, pager, next" :total="Number(count)">
      </el-pagination>
    </div>

    <bottoms></bottoms>
  </div>
</template>
<script>
import bottoms from "./Bottom.vue";
// components
import { Article } from "../request/api.js";
export default {
  components: {
    bottoms,
  },
  data() {
    return {
      page: 1,
      limit: 10,
      count: "",
      list: [],
    };
  },
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f4f5f7");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  created() {
    //文章列表
    this.ArticleList();
    sessionStorage.setItem("name", "3");
  },
  methods: {
    xiangqing(e){
      let article_id = e.article_id
      this.$router.push({name:'Customer',query: {article_id:article_id}})
    },
    //文章列表
    ArticleList() {
      Article({
        page: this.page,
        limit: this.limit,
      }).then(
        (res) => {
          if (res.status == 200) {
            this.list = res.data.list;
            this.count = res.data.count;
          }
        },
        (fail) => {
          console.log(fail);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.chang {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .neirong {
    margin-top: 84px;
    width: 100%;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .dataList {
    width: 1170px;
    // height: 994px;
    background: #ffffff;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    .dataList-yi {
      width: 100%;
      height: 196px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .dataList-yi-nei {
        width: 1092px;
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f4f5f7;
        .dataList-yi-nei-left {
          width: 254px;
          height: 142px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            display: block;
          }
        }
        .dataList-yi-nei-right {
          width: 821px;
          height: 142px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          color: #333333;
        }
      }
      .dataList-yi-pros {
        position: absolute;
        right: 24px;
        bottom: 24px;
        display: flex;
        color: #333333;
        font-size: 16px;
      }
      .dataList-yi-pros-right {
        margin-left: 36px;
      }
    }
    .dataList-yi:hover {
      background: rgba(198, 21, 47, 0.1);
    }
  }
  .fenye {
    margin-top: 50px;
  }
}
</style>