<template>
  <div class="chang">
    <div class="neirong">
      <img
        src="https://cdn.bjtdba.com/vod/image/2022-07-13/20220713143514951.png"
        alt=""
        mode="widthFix"
      />
    </div>
    <!-- 关于我们 -->
    <div class="gunayu">
      <div class="neibu">
        <img
          src="https://cdn.bjtdba.com/vod/image/2023-02-17/20230217111927561.png"
          alt=""
          mode="widthFix"
        />
      </div>
    </div>
    <bottoms></bottoms>
  </div>
</template>
<script>
import bottoms from "./Bottom.vue";
export default {
  components: {
    bottoms,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.chang {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .neirong {
    margin-top: 84px;
    width: 100%;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .gunayu {
    width: 100%;
    height: 695px;
    display: flex;
    justify-content: center;
    align-items: center;
    .neibu {
      width: 1170px;
      cursor: pointer;
      box-shadow: 1px 9px 16px 3px rgba(204, 224, 250, 0.49);
      -webkit-box-shadow: 1px 9px 16px 3px rgba(204, 224, 250, 0.49);
      -moz-box-shadow: 1px 9px 16px 3px rgba(204, 224, 250, 0.49);
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
}
</style>