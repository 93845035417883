import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Bottom from '../views/Bottom.vue'
import ChengzhongDachang from '../views/ChengzhongDachang.vue'
import Product from '../views/Product.vue'
import Customer from '../views/Customer.vue'
import Briefintroduction from '../views/Briefintroduction.vue'
import Volunteers from '../views/Volunteers.vue'
import DepositInsurance from '../views/DepositInsurance.vue'
import Benefit from '../views/Benefit.vue'
import Farmers from '../views/Farmers.vue'
import FourZeroFour from '../views/404.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isShowTabBar: true,//显示
    }
  },
  {
    path: '/404',
    name: 'FourZeroFour',
    component: FourZeroFour
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/bottom',
    name: 'Bottom',
    component: Bottom
  },
  {
    path: '/chengzhongdachang.html',
    name: 'ChengzhongDachang',
    component: ChengzhongDachang,
    meta: {
      isShowTabBar: true,//显示
    }
  },
  {
    path: '/product.html',
    name: 'Product',
    component: Product,
    meta: {
      isShowTabBar: true,//显示
    }
  },
  {
    path: '/customer.html',
    name: 'Customer',
    component: Customer,
    meta: {
      isShowTabBar: true,//显示
    }
  },
  {
    path: '/briefintroduction.html',
    name: 'Briefintroduction',
    component: Briefintroduction,
    meta: {
      isShowTabBar: true,//显示
    },
    
  },
  {
    path: '/volunteers.html',
    name: 'Volunteers',
    component: Volunteers,
    meta: {
      isShowTabBar: true,//显示
    },
    
  },
  {
    path: '/depositlnsurance.html',
    name: 'DepositInsurance',
    component: DepositInsurance,
    meta: {
      isShowTabBar: true,//显示
    },
    
  },
  {
    path: '/benefit.html',
    name: 'Benefit',
    component: Benefit,
    meta: {
      isShowTabBar: true,//显示
    },
    
  },
  {
    path: '/farmers.html',
    name: 'Farmers',
    component: Farmers,
    meta: {
      isShowTabBar: true,//显示
    },
    
  },
  //brief introduction
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//首页视频
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}

export default router
