<template>
  <div class="Benefit">
    <div class="Benefit-top">
      <img
        src="https://cdn.bjtdba.com/vod/image/2022-09-21/20220921201358712.png"
        alt=""
        mode="widthFix"
      />
    </div>
    <div class="Benefit-zhon">
      <div class="Benefit-zhon-yi" v-for="(item, index) in num" :key="index">
        <div class="Benefit-zhon-yi-img">
          <img :src="item.url" alt="" />
        </div>
        <div class="Benefit-zhon-yi-text">
          {{ item.text }}
        </div>
      </div>
    </div>
    <bottoms></bottoms>
  </div>
</template>
<script>
import bottoms from "./Bottom.vue";
export default {
  components: {
    bottoms,
  },
  data() {
    return {
      num: [
        {
          url: "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921201151648.png",
          text: "储蓄保用户达到五百万人，将设立一百万民生专项基金",
        },
        {
          url: "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921201218766.png",
          text: "配合政府开设惠民便民开通及咨询储蓄保服务窗口",
        },
        {
          url: "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921201246956.png",
          text: "配合民政局实施菜篮子工程社区居民覆盖和参与菜篮子工程的商户进驻平台",
        },
      ],
    };
  },
  created() {},
  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f4f5f7");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.Benefit {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .Benefit-top {
    margin: 84px 0 90px 0;
    width: 100%;
    height: 460px;
    background-color: cornflowerblue;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .Benefit-zhon {
    display: flex;
    width: 1170px;
    height: 287px;
    justify-content: space-between;
    align-items: center;
    .Benefit-zhon-yi {
      width: 376px;
      height: 100%;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .Benefit-zhon-yi-img {
        width: 100%;
        height: 190px;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .Benefit-zhon-yi-text {
        width: 100%;
        height: 97px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        padding: 0 25px;
        box-sizing: border-box;
        letter-spacing: 2px;
      }
    }
    .Benefit-zhon-yi:hover {
      box-shadow: 0px 8px 65px 0px rgba(198, 21, 47, 0.3);
      -webkit-box-shadow: 0px 8px 65px 0px rgba(198, 21, 47, 0.3);
      -moz-box-shadow: 0px 8px 65px 0px rgba(198, 21, 47, 0.3);
    }
  }
}
</style>