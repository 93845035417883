import axios from 'axios'
import { Message, Loading } from 'element-ui'
const ConfigBaseURL = 'https://app.bjtdba.com/api/' //默认路径，这里也可以使用env来判断环境
let loadingInstance = null //这里是loading
//使用create方法创建axios实例
// var authorization = {
//   toke:"",
//   jiamiL:"",
// }

export const Service = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: 'post',
  headers: {
    // 'authorization': localStorage.getItem('token')
  },
})
// 添加请求拦截器
Service.interceptors.request.use(config => {
  loadingInstance = Loading.service({
    lock: true,
    text: 'loading...'
  })
  //判断登录接口传authorization 非 验证码
  // if (config.url == '/api/auth/verify' || config.url == '/api/xieyi/detail') {
  //   config.headers = {
  //     "X-Token": ""
  //   }
  // } else {
  //   config.headers.authorization = localStorage.getItem('token');
  // }

  return config
})
// 添加响应拦截器
Service.interceptors.response.use(response => {
  loadingInstance.close()
  // console.log(response)
  return response.data
}, error => {
  console.log('TCL: error', error)
  const msg = error.Message !== undefined ? error.Message : ''
  Message({
    message: '网络错误' + msg,
    type: 'error',
    duration: 1 * 1000
  })
  loadingInstance.close()
})
