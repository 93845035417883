<template>
  <div class="home">
    <div class="home-url" @touchmove.prevent @mousewheel.prevent>
      <div class="sdfg">
        <vueMiniPlayer ref="vueMiniPlayer" style="z-index: 0" :video="video" :mutex="true"
          @fullscreen="handleFullscreen" />
      </div>
    </div>
    <div class="neirong">
      <el-carousel height="600px" indicator-position="none" :interval="2500">
        <el-carousel-item v-for="(item, index) in images" :key="index">
          <img :src="item.url" alt="" @click="imags(index)" mode="widthFix" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="zhanlue">
      <div class="zhanlu_text">战略合作伙伴</div>
      <div class="zhanlu-img">
        <img src="https://cdn.bjtdba.com/vod/image/2023-02-17/20230217111740276.png" alt="" mode="widthFix" />
      </div>
    </div>
    <bottoms></bottoms>
  </div>
</template>

<script>
import Vue from "vue";
import vueMiniPlayer from "vue-mini-player";
import "vue-mini-player/lib/vue-mini-player.css";
Vue.use(vueMiniPlayer);

import bottoms from "./Bottom.vue";
export default {
  components: {
    bottoms,
  },
  data() {
    return {
      video: {
        // 视频播放源
        url: "https://cdn.bjtdba.com/vod/video/2022-06-29/20220629112709397.mp4",
        // 视频海报
        cover:
          "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921181705878.jpg",
        muted: false, // 是否静音播放
        loop: false, // 视频是否循环播放
        preload: "auto", // 视频预加载
        poster: "", // 原生视频默认海报暂不设置
        volume: 1, // 默认音量
        autoplay: false, // 视频自动播放
      },
      images: [
        // {
        //   url: "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921155356909.png",
        // },
        // {
        //   url: "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921155628259.png",
        // },
        {
          url: "https://cdn.bjtdba.com/vod/image/2023-02-17/20230217115240355.png",
        },
        {
          url: "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921155452814.png",
        },
        {
          url: "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921155411369.png",
        },
        {
          url: "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921155528902.png",
        },
        {
          url: "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921155543334.png",
        },
        {
          url: "https://cdn.bjtdba.com/vod/image/2022-09-21/20220921155556685.png",
        },
      ],
      isindex: 0,
    };
  },
  created() { },
  computed: {
    $video() {
      return this.$refs.vueMiniPlayer.$video;
    },
  },
  methods: {
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    handleFullscreen(e) {
      console.log(e);
    },
  },
};
</script>
<style lang="scss" scoped>
.nihsjdf {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1111;

  .sdfg {
    width: 1000px;
    height: 600px;
  }
}

.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .home-url {
    width: 100%;
    height: 600px;
    margin: 84px 0 140px 0;

    .sdfg {
      width: 100%;
      height: 100%;
      z-index: -10;
    }
  }

  .neirong {
    width: 100%;
    cursor: pointer;

    img {
      width: 100%;
      height: inherit;
      min-height: 360px;
      min-width: 1400px;
      display: block;
    }
  }

  .zhanlue {
    width: 689px;
    height: 164px;
    margin-top: 89px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .zhanlu_text {
      font-size: 34px;
      font-weight: 400;
      color: #333333;
    }

    .zhanlu-img {
      width: 689px;
      height: 90px;
      margin-top: 40px;

      img {
        width: 100%;
        display: block;
      }
    }
  }
}
</style>
