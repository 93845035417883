<template>
  <div id="app">
    <div id="nav">
      <div id="header" v-if="$route.meta.isShowTabBar">
        <div class="zong">
          <div class="left">
            <img
              src="https://cdn.bjtdba.com/vod/image/2022-09-21/20220921132401843.png"
              alt=""
              mode="widthFix"
            />
          </div>
          <div class="right">
            <div class="text">
              <div
                class="is"
                v-for="(item, index) in mus"
                :key="index"
                @click="dianji(index, item.name)"
                :style="isindex == index ? 'color:#fe0032' : 'color:#333333'"
                v-on:mouseover="changeActive(item.name)"
              >
                {{ item.name }}
                <div class="types" v-if="isindex == index"></div>
              </div>
            </div>
            <div class="xiala" v-if="isxianshui" @mouseleave="yc()">
              <div
                class="xiala-yi"
                @click="huadong(`志愿服务活动执行模版（校园版）`)"
              >
                志愿服务活动执行模版（校园版）
              </div>
              <div
                class="xiala-yi"
                @click="huadong(`志愿服务活动执行模版（社区版）`)"
              >
                志愿服务活动执行模版（社区版）
              </div>
            </div>
            <div class="isji" @click="tiao">商户后台</div>
          </div>
        </div>
      </div>
      <!-- 11 -->
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isindex: 0,
      isxianshui: false,
      mus: [
        {
          name: "首页",
        },
        {
          name: "公司简介",
        },
        {
          name: "大事记",
        },
        {
          name: "企业动态",
        },
        {
          name: "储蓄保",
        },
        {
          name: "惠民专区",
        },
        {
          name: "惠农专区",
        },
        {
          name: "志愿者在行动",
        },
      ],
    };
  },
  created() {
    if (sessionStorage.getItem("name") == undefined) {
      this.isindex = 0;
    } else {
      this.isindex = sessionStorage.getItem("name");
    }
    
  },
  methods: {
    changeActive(e) {
      if (e == "志愿者在行动") {
        this.isxianshui = true;
      } else {
        this.isxianshui = false;
      }
    },
    yc(){
      this.isxianshui = false;
    },
    huadong(e) {
      this.isindex = 7;
      this.isxianshui = false;
      sessionStorage.setItem("title", e);
      this.$router.push({ name: "Volunteers",query: {name:e}});
    },
    dianji(e, name) {
      this.isindex = e;
      sessionStorage.setItem("name", this.isindex);
      if (name == "首页") {
        this.$router.push({ name: "Home" });
      } else if (name == "大事记") {
        this.$router.push({ name: "ChengzhongDachang" });
      } else if (name == "企业动态") {
        this.$router.push({ name: "Product" });
      } else if (name == "公司简介") {
        this.$router.push({ name: "Briefintroduction" });
      } else if (name == "志愿者在行动") {
        this.$router.push({ name: "Volunteers" });
        sessionStorage.setItem("title", `志愿服务活动执行模版（校园版）`);
      }else if(name=="储蓄保"){
        this.$router.push({ name: "DepositInsurance" });
      }else if(name=="惠民专区"){
          this.$router.push({ name: "Benefit"});
      }else if(name=="惠农专区"){
        this.$router.push({ name: "Farmers"});
      }
    },
    tiao() {
      window.open("https://app.bjtdba.com/merchant/login");
    },
  },
};
</script>
<style lang="scss" scoped>
#header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11111;
  .zong {
    // width: 1169.9px;
    width: 1404px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    .left {
      width: 403px;
      height: 100%;
      cursor: pointer;
      img {
        width: 100%;
        // height: 100%;
        display: block;
      }
    }
    .right {
      // width: 663px;
      width: 817px;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      .text {
        // width: 464px;
        width: 684px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .is {
          font-size: 16.08px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          cursor: pointer;
          position: relative;
          .types {
            position: absolute;
            top: 34px;
            width: 100%;
            height: 2px;
            background-color: #fe0032;
          }
        }
      }
      .xiala {
        width: 290px;
        height: 134px;
        background: #ffffff;
        position: absolute;
        top: 68px;
        right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-size: 16px;
        color: #333333;
      }
      .xiala-yi:hover {
        color: #c6152f;
        cursor: pointer;
      }
      .isji {
        width: 64px;
        height: 100%;
        font-size: 16px;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .isji:hover {
        color: #fe0032;
        cursor: pointer;
      }
    }
  }
}
</style>

