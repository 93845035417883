import { Service } from './axios'
// 数据查看-列表
export function Getdatalistl(data) {
    return Service({
        url: '/api/v1/operate/data_list',
        method: 'get',
        params: { page: data.page, limit: data.limit, type: data.type }
    })
}
export function Postverify(data) {
    //发送验证码
    return Service({
      url: '/api/auth/verify',
      method: 'post',
      data: data,
    })
  }
  //文章列表
  export function Article(data) {
    return Service({
      url: 'article/lst/53',
      method: 'get',
      params: { page: data.page, limit: data.limit,}
    })
  }
    //文章详情
    export function detail(data) {
      return Service({
        url: 'article/detail/'+ data.article_id,
        method: 'get',
      })
    }